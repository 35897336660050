import cn from '@Infowijs-eng/component-library/modules/cn'
import { Button } from '@Infowijs-eng/component-library/components'
import { ExclamationCircle, ExclamationTriangle, XMark } from '@Infowijs-eng/component-library/icons'

enum ToastType {
  ERROR = 'error',
  WARNING = 'warning',
}

interface ToastProps {
  message?: string
  type?: ToastType
  onClose?: () => void
}

export default function Toast({ message = undefined, type = ToastType.ERROR, onClose = () => {} }: ToastProps) {
  if (message === undefined) {
    return null
  }

  const backgroundColor = type === ToastType.ERROR ? 'bg-red-100' : 'bg-orange-100'
  const errorIcon = type === ToastType.ERROR ? <ExclamationCircle /> : <ExclamationTriangle />
  const errorIconBackground = type === ToastType.ERROR ? 'bg-red-200' : 'bg-orange-200'
  const errorIconForeground = type === ToastType.ERROR ? 'text-red-600' : 'text-orange-600'
  return (
    <div className={cn(' w-full h-16 absolute top-0 items-center flex justify-between px-8', backgroundColor)}>
      <div className="flex flex-row items-center gap-4 font-bold">
        <div className={cn('p-2 rounded-full', errorIconBackground, errorIconForeground)}>
          {errorIcon}
        </div>
        {message}
      </div>
      <Button
        onClick={onClose}
        small
        neutral
        leadingAddon={<XMark />}
      />
    </div>
  )
}
