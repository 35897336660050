import React, {
  useEffect,
  useState,
} from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import { t } from '@lingui/macro'
import { ArrowsLoop, Person } from '@Infowijs-eng/component-library/icons'
import {
  Alert,
  Button,
  TextInput,
} from '@Infowijs-eng/component-library/components'
import { cn } from '@Infowijs-eng/component-library/modules'
import getType from '../../selectors/customer/getType.ts'

function EmailLogin({
  signIn,
  sessionRequest,
  signOut,
  emailAddress,
  refreshToken,
  errorMessage,
}) {
  const [username, setUsername] = useState(emailAddress || '')
  const [submitting, setSubmitting] = useState(false)

  const { customerProduct } = useParams()
  const customerProductType = useSelector(getType)

  useEffect(() => {
    if (sessionRequest) {
      setSubmitting(false)
    }
  }, [setSubmitting, sessionRequest])

  const onSubmit = (ev) => {
    ev.preventDefault()
    if (sessionRequest) {
      signOut()
    } else if (username) {
      signIn({
        type: customerProductType ?? 'hoy',
        username,
        communityName: customerProduct,
      })
      setSubmitting(true)
    }
  }

  const submitted = !!sessionRequest || !!refreshToken

  let trailingAddon
  if ((submitting && !errorMessage) || submitted) {
    trailingAddon = <ArrowsLoop className="text-white animate-spin rotate-180" />
  } else {
    trailingAddon = <Person />
  }

  return (
    <div className="flex flex-col gap-8">
      <div className="flex flex-col items-center gap-4" onSubmit={onSubmit}>
        <div className="flex flex-col md:flex-row space-y-4">
          <form autoComplete="off" className="flex flex-col md:flex-row gap-4">
            <TextInput
              className={cn(
                'h-10 w-72 disabled:bg-theme-200',
                !!submitted && !errorMessage && 'placeholder:text-white',
              )}
              name="email"
              type="email"
              placeholder={submitted ? t({ id: 'email-login.received' }) : t({ id: 'email-login.placeholder' })}
              value={submitted ? '' : username}
              onChange={(e) => setUsername(e.target.value)}
              disabled={!!submitted}
              autoComplete="off"
              required
              trailingAddon={trailingAddon}
              autoFocus
            />
            <Button
              type="submit"
              primary={!submitted}
            >
              {submitted ? t({ id: 'common.cancel' }) : t({ id: 'email-login.login' })}
            </Button>
          </form>
          {errorMessage && (
            <Alert title={errorMessage} />
          )}
        </div>
      </div>
    </div>
  )
}

EmailLogin.propTypes = {
  signIn: PropTypes.func.isRequired,
  signOut: PropTypes.func.isRequired,
  emailAddress: PropTypes.string,
  sessionRequest: PropTypes.shape({
    status: PropTypes.string,
  }),
  refreshToken: PropTypes.string,
  errorMessage: PropTypes.string,
}

EmailLogin.defaultProps = {
  sessionRequest: null,
  refreshToken: null,
  emailAddress: '',
  errorMessage: null,
}

export default EmailLogin
