import { getAuthService } from '@Infowijs-eng/component-library/modules'
import EntreeButton from '../../images/entree_button.png'

interface EntreeLoginProps {
  productType?: string
  customerProduct?: string
}

export default function EntreeLogin({ productType = undefined, customerProduct = undefined }: EntreeLoginProps) {
  const authService = getAuthService()
  const params = new URLSearchParams()
  if (productType) {
    params.set('type', productType)
  }
  if (customerProduct) {
    params.set('customerProduct', customerProduct)
  }

  const redirectUrl = `${authService}/auth/entree${params.toString() ? `?${params.toString()}` : ''}`

  return (
    <a href={redirectUrl} className="text-blue-500 h-10">
      <img className="h-10" src={EntreeButton} alt="Inloggen via Entree" />
    </a>
  )
}
