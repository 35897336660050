import { Heading } from '@Infowijs-eng/component-library/components'
import React, {
  useEffect,
  useLayoutEffect,
  useState,
} from 'react'
import {
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import { t } from '@lingui/macro'
import { Trans, useLingui } from '@lingui/react'
import {
  usePromisifyDispatch,
} from '@Infowijs-eng/component-library/modules'
import { useSelector } from 'react-redux'
import EntreeLogin from '../components/EmailLogin/EntreeLogin.tsx'
import ScanContainer from '../components/HoyScan/ScanContainer'
import AppLinks from '../components/AppLinks'
import EmailLogin from '../components/EmailLogin'
import getRedirectUrlForCustomerProduct from '../modules/getRedirectUrlForCustomerProduct.ts'
import getClientTransferToken from '../selectors/authentication/getClientTransferToken'
import getUserPermissions from '../selectors/authentication/getUserPermissions'
import getName from '../selectors/customer/getName'
import getPremiumAppName from '../selectors/customer/getPremiumAppName'
import getPremiumAppSlogan from '../selectors/customer/getPremiumAppSlogan'
import getTitle from '../selectors/customer/getTitle.ts'
import isLoaded from '../selectors/customer/isLoaded'
import LoadingScreen from './LoadingScreen'
import Layout from '../components/Layout'
import requestCustomerMeta from '../actions/customer/requestCustomerMeta'
import Toast from '../components/Toast.tsx'

function SignInScreen() {
  const navigate = useNavigate()
  const promisifyDispatch = usePromisifyDispatch()

  const transferToken = useSelector(getClientTransferToken)
  const customerLoaded = useSelector(isLoaded)
  const customerName = useSelector(getName)
  const customerPremiumAppName = useSelector(getPremiumAppName)
  const customerPremiumAppSlogan = useSelector(getPremiumAppSlogan)
  const customerTitle = useSelector(getTitle)
  const { customerProduct, productType } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()

  const [loading, setLoading] = useState(false)
  const [toastMessage, setToastMessage] = useState(undefined)
  const [toastType, setToastType] = useState('error')
  const userPermissions = useSelector(getUserPermissions)

  const { i18n } = useLingui()

  useEffect(() => {
    if (transferToken != null) {
      let type = productType
      if (!type) {
        type = 'hoy'
      }
      const endpoint = getRedirectUrlForCustomerProduct(
        type,
        customerName,
        userPermissions,
      )
      window.location = `${endpoint}/?token=${transferToken}`
    }
  }, [customerName, productType, userPermissions, transferToken, navigate])

  useEffect(() => {
    if (productType !== 'brugger') return

    document.title = t({ id: 'Brugger - Sign in' })
    const favicon = document.querySelector('link[rel="icon"]')
    if (favicon) {
      favicon.href = '/favicon-brugger.ico'
    }

    const appleTouchIcon = document.querySelector('link[rel="apple-touch-icon"]')
    if (appleTouchIcon) {
      appleTouchIcon.href = '/logo192-brugger.png'
    }
  }, [productType])

  useLayoutEffect(() => {
    if (customerProduct && !customerLoaded && !loading) {
      setLoading(true)
      promisifyDispatch(requestCustomerMeta({
        customerProduct,
        productType,
      })).then(() => {
        setLoading(false)
      }).catch((e) => {
        if (!e || !e.response || e.response.status !== 404) {
          // eslint-disable-next-line no-alert
          alert(t({ id: 'error.default' }))
        }
        navigate('/')
        setLoading(false)
      })
    }
  }, [customerProduct, productType, customerLoaded, loading, setLoading, navigate, requestCustomerMeta])

  useEffect(() => {
    const error = searchParams.get('error')
    switch (error) {
      case 'server_error':
        setToastMessage(i18n._('Er is iets mis gegaan'))
        setToastType('error')
        break
      case 'access_denied':
        setToastMessage(i18n._('Je hebt geen toegang tot deze app via Entree Federatie'))
        setToastType('warning')
        break
      case 'user_error':
        setToastMessage(i18n._('Inlogpoging mislukt of onderbroken'))
        setToastType('warning')
        break
      case 'multi_user':
        setToastMessage(i18n._('Je hebt meerdere accounts, log in met je e-mailadres'))
        setToastType('warning')
        break
      default:
        setToastMessage(undefined)
        break
    }
  }, [searchParams])

  const onCloseToast = () => {
    setToastMessage(undefined)
    setSearchParams({}, { replace: true })
  }

  let appName = customerPremiumAppName || 'Hoy'
  if (productType === 'brugger') {
    appName = customerTitle
  }

  if (loading) {
    return (
      <LoadingScreen />
    )
  }

  return (
    <Layout>
      <Toast message={toastMessage} type={toastType} onClose={onCloseToast} />
      <div
        className={`flex-col self-center xl:self-start lg:max-w-screen-lg w-full mx-auto md:px-6 xl:mt-36
          space-y-10`}
      >
        <div>
          <header className="text-center">
            <Heading
              fontSize={Heading.size.H1}
            >
              <span className="text-theme-500">
                <Trans id="sign-in.welcome" values={{ customerPremiumAppName: appName }}>
                  {'Welkom bij {customerPremiumAppName}!'}
                </Trans>
              </span>
            </Heading>
            <p className="text-gray-300 md:text-lg font-bold leading-relaxed">
              { productType !== 'brugger' && (customerPremiumAppSlogan || (
                <Trans id="sign-in.subtitle">De nieuwe standaard voor schoolcommunicatie</Trans>
              ))}
              { productType === 'brugger' && (
                <Trans id="sign-in.subtitle.brugger">Alle aanmeldingen eindelijk online</Trans>
              )}
            </p>
          </header>
        </div>
        {(!productType || productType === 'hoy') && (
          <div className={customerProduct ? 'hidden md:flex justify-center' : 'flex justify-center'}>
            <ScanContainer />
          </div>
        )}
        <div className="flex flex-col md:flex-col-reverse gap-28 md:gap-8 items-center">
          <div className="flex flex-col md:flex-row gap-4 md:gap-8 justify-normal items-center">
            {!!customerProduct && <EmailLogin />}
            {!!customerProduct && <div className="font-bold text-gray-300"><Trans id="of" /></div>}
            <EntreeLogin productType={productType} customerProduct={customerProduct} />
          </div>
          <hr className="hidden md:block w-full border-gray-100" />
          <div className="md:hidden">
            {(!productType || productType === 'hoy') && <AppLinks />}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default SignInScreen
